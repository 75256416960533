import React from "react"
import { withTheme } from "styled-components"
import Button from "./button"

const FormButton = ({ theme, children, ...rest }) => {
  return (
    <Button {...rest} color={theme.buttonColor}>
      {children}
    </Button>
  )
}

export default withTheme(FormButton)
