import React from "react"
import styled, { useTheme } from "styled-components"
import { breakpoints } from "../../theme"
import FormButton from "../../components/form-button"
import Layout from "../../components/layout"
import IntlText from "../../components/intl-text"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./thank-you.scss";

const image = {
  dark: "src/images/contact/dark/contact-success.png",
  light: "src/images/contact/light/contact-success.png",
};

const Container = styled.div`
  margin-top: 103px;
  margin-bottom: 88px;
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 134px;
    margin-bottom: 161px;
  }
  @media (min-width: ${breakpoints.extralarge}) {
    margin-top: 176px;
    margin-bottom: 155px;
  }
`

const ThankYou = () => {
  const { isDark } = useTheme();
  const data = useStaticQuery(graphql`
      query imageThankYou {
        allFile(filter: {base: {eq: "contact-success.png"}}) {
          edges {
            node {
              id
              base
              relativePath
                childImageSharp {
                  gatsbyImageData(
                  layout: CONSTRAINED,
                  aspectRatio: 1,
                  formats: [WEBP]
                  )
                }
            }
          }
        }
      }
    `);

  const filterImage = data.allFile.edges.filter((i) => i.node.relativePath === isDark ? image.dark : image.light)

  return (
    <Layout
      hideContact
      pageTitle="Contact"
      title="Web and Mobile App Development - Sysgarage"
      description="If you have an idea in your mind and you want to make it real, contact us now and start working together."
    >
      <Container className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="mb-4 w-25">
              <GatsbyImage
                objectFit="contain"
                className="image-thank-you"
                image={getImage(filterImage[0].node.childImageSharp)}
                alt="Thank you for contact us" />
            </div>
            <p className="title2-Emphasis" style={{ marginBottom: "18px" }}>
              <IntlText id="pages.thankyou.title" />
            </p>
            <p className="body1-Emphasis" style={{ marginBottom: "40px" }}>
              <IntlText id="pages.thankyou.subtitle" />
            </p>
            <Link to="/projects">
              <FormButton>
                <IntlText id="pages.thankyou.button" />
              </FormButton>
            </Link>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ThankYou
